let navigation = document.getElementsByClassName('navigation');
let navigationHeight = navigation[0].offsetHeight;
let navigationOffset = navigation[0].offsetTop;

$(window).scroll(function(){
    let navigationFixed = document.getElementsByClassName('navigation__fixed');
    let header = document.getElementsByClassName('header');

    if ($(window).scrollTop() >= navigationOffset) {
        for (let i = 0; i < navigation.length; i++) {
            navigation[i].style.height = navigationHeight + 'px';
            navigation[i].classList.add('navigation--active');
        }
    }

    if ($(window).scrollTop() < navigationOffset) {
        for (let x = 0; x < navigation.length; x++) {
            navigation[x].style.height = 'auto';
            navigation[x].classList.remove('navigation--active');
        }
    }
});


// Function to disable menu-item which has childrens
document.addEventListener('DOMContentLoaded', () => {

	const mediaQuery = window.matchMedia('(min-width: 1299px)')

	// Clickhandler which disabled function of a href
	const clickHandler = (event) => {
		event.preventDefault();
	}

	// Target all menu-items which have a sub-menu child
	const navItemsWithSubmenu = document.querySelectorAll('.menu-item-has-children');
	
	// Looping trough all said menu-items
	for (let i = 0; i < navItemsWithSubmenu.length; i++) {

		// Define current item
		let navItemWithSubmenu = navItemsWithSubmenu[i];

		// Get height of current item
		const navItemWithSubmenuHeight = navItemWithSubmenu.offsetHeight;
		
		// Get current item link
		const navItemSubmenuLink = navItemWithSubmenu.querySelector('a');

		// Add the clickHandler
		navItemSubmenuLink.addEventListener('click', clickHandler, false);

		// Get current item submenu
		const navItemSubmenu = navItemWithSubmenu.querySelector('ul');

		if(mediaQuery.matches) {
			// Set margin top on submenu
			navItemSubmenu.style.marginTop = `${navItemWithSubmenuHeight - 5}` + "px";
		}
	}
});
