// This is the bottle neck for the application all sass and js files will be loaded through this file.
// config file is for webpack.
import config from '@/config';
import '../scss/main.scss';

// Load JS files from the components directory
import './components/swiper-sliders';
import './components/tabs';
import './components/fixed-navigation';
import './components/table-hover';
import './components/mixitup';

import './important/object-fit.js';

// import './important/lazyload.js';
// import './optional/swiper.js';


document.addEventListener('DOMContentLoaded', function () {
    function mobileMenu() {
        const toggle = document.querySelectorAll( '.mobile-toggle' );
        const nav = document.querySelector( '.menu' );
        const body = document.querySelector( 'body' );

        toggle[0].addEventListener( 'click', function()  {
            nav.classList.toggle( 'menu--active' );
            body.classList.toggle( 'menu--active' );
        });
    }

    mobileMenu();


    function YtOverlay() {
        const overlay = document.querySelectorAll( '.img-overlay' );

        for ( let i = 0; i < overlay.length; i++ ) {
            overlay[i].addEventListener( 'click', function() {
               this.classList.toggle( 'img-overlay--hidden' );

               const iframe = document.getElementsByTagName( "iframe" )[i];
               iframe.src += "?autoplay=1";
            });
        }
    }

    YtOverlay();
});
